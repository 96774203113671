exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-article-library-js": () => import("./../../../src/pages/article-library.js" /* webpackChunkName: "component---src-pages-article-library-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lectures-publications-js": () => import("./../../../src/pages/lectures-publications.js" /* webpackChunkName: "component---src-pages-lectures-publications-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-trust-js": () => import("./../../../src/pages/trust.js" /* webpackChunkName: "component---src-pages-trust-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

