import React from "react"
import { Link } from "gatsby"
import Burger from "./Burger"
import Navigation from "./Navigation"
import { headerStyles } from "../styles/NavStyles.module.scss"

const Header = () => {
  // const [scroll, setScroll] = useState(false)

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 50)
  //   })
  // }, [])

  return (
    <header className={headerStyles}>
      <h1 className="logo-cont">
        <Link to="/">Dr Tom Bereznicki</Link>
      </h1>
      <Burger />
      <Navigation />
    </header>
  )
}

export default Header
