import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "../styles/GlobalStyles.scss"
import "../styles/Typography.scss"
import { motion, AnimatePresence } from "framer-motion"
import CookieBanner from "./CookieBanner"

const variants = {
  pre: {
    opacity: 0,
    y: "1rem",
    transition: {
      type: "tween",
      duration: 0.25,
    },
  },
  visible: {
    opacity: 1,
    y: "0rem",
    transition: {
      type: "tween",
      duration: 0.25,
    },
  },
  post: {
    opacity: 0,
    y: "-1rem",
    transition: {
      type: "tween",
      duration: 0.25,
    },
  },
}

const Layout = ({ children, uri }) => {
  const uriNoHash = uri.split("#")[0]
  return (
    <>
      <Header />
      {/* <AnimatePresence> */}
      <AnimatePresence exitBeforeEnter>
        {children && (
          <motion.main
            key={uriNoHash}
            variants={variants}
            initial="pre"
            animate="visible"
            exit="post"
            className="main-body"
          >
            {children}
          </motion.main>
        )}
      </AnimatePresence>
      <CookieBanner />
      <Footer />
    </>
  )
}

export default Layout
