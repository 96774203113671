export const mainMenuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/trust",
    title: "Charitable Dental Education Trust",
  },
  {
    path: "/about",
    title: "About Dr Tom",
  },
  {
    path: "/appointments",
    title: "Appointments",
  },
  {
    path: "/lectures-publications",
    title: "Lectures & Publications",
  },
  {
    path: "/article-library",
    title: "Article Library",
  },
  {
    path: "/videos",
    title: "Educational Videos & Webinars",
  },
]

export const socialMenuItems = []

export const footerMenuItems = [
  {
    path: "/contact",
    title: "contact",
  },
  {
    path: "/privacy",
    title: "privacy",
  },
  {
    path: "/cookies",
    title: "cookies",
  },
]
