import React, { useContext } from "react"
import { burgerStyles } from "../styles/NavStyles.module.scss"
import MenuContext from "./MenuContext"

const Burger = () => {
  const [isOpen, setNav] = useContext(MenuContext)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <button
      onClick={toggleNav}
      type="button"
      className={`${burgerStyles} ${isOpen ? "open" : "closed"}`}
    >
      Menu
      <div>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  )
}

export default Burger
